import React, { useContext } from 'react';
import Layout from 'components/Layout/Layout';
import OurOffer from 'pages/HomePage/OurOfferSection/OurOfferSection';
import ContactSection from 'pages/HomePage/ContactSection/ContactSection';
import RepresentationSection from 'pages/HomePage/RepresentationSection/RepresentationSection';
import LandingSection from 'pages/HomePage/LandingSection/LandingSection';
import { useQuery } from '@apollo/client';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

import CarouselSection from './CarouselSection/CarouselSection';
import { GET_CAROUSEL_ITEMS, GET_LANDING_MEDIA, GET_REPRESENTATION_LOGOS } from './HomePage.queries';
import { LanguageContext } from 'contexts/LanguageContext';

interface ICarouselElementsData {
    carouselElements: {
        data: ICarouselElement[];
    };
}

export interface ICarouselElement {
    id: number;
    attributes: {
        text: string;
        order: number;
        button_link: string;
        button_text: string;
        logo: {
            data: {
                id: number;
                attributes: {
                    url: string;
                    alternativeText: string;
                };
            };
        };
        image: {
            data: {
                id: number;
                attributes: {
                    url: string;
                    alternativeText: string;
                };
            };
        };
    };
}

interface IRepresentationLogosData {
    representationLogos: {
        data: IRepresentationLogo[];
    };
}

export interface IRepresentationLogo {
    id: number;
    attributes: {
        website_url: string;
        logo: {
            data: {
                attributes: {
                    url: string;
                    alternativeText: string;
                };
            };
        };
    };
}

interface ILandingPageMedia {
    landingPageMedia: {
        data: LandingMedia;
    };
}

export interface LandingMedia {
    id: number;
    attributes: {
        content: {
            data: {
                attributes: {
                    url: string;
                    alternativeText: string;
                    mime: string;
                };
            };
        };
        video_poster: {
            data: {
                attributes: {
                    url: string;
                    alternativeText: string;
                };
            };
        };
        show_text: boolean;
        main_text: string;
        secondary_text: string;
        cta_text: string;
        cta_link: string;
        image_over_text: {
            data: {
                attributes: {
                    url: string;
                    alternativeText: string;
                };
            };
        };
    };
}

interface Props {}

const HomePage = (props: Props) => {
    const { language } = useContext(LanguageContext);

    const { data: landingMedia, loading: loadingLandingMedia } = useQuery<ILandingPageMedia>(GET_LANDING_MEDIA, {
        variables: { locale: language.localeCode },
    });
    const { data: repLogos, loading: loadingRepLogos } = useQuery<IRepresentationLogosData>(GET_REPRESENTATION_LOGOS);
    const { data: carouselElementsData, loading: loadingCarousel } = useQuery<ICarouselElementsData>(
        GET_CAROUSEL_ITEMS,
        { variables: { locale: language.localeCode } }
    );

    if (loadingRepLogos && loadingLandingMedia && loadingCarousel) return <LoadingScreen />;

    return (
        <Layout>
            <LandingSection media={landingMedia?.landingPageMedia?.data} />
            {/* <ProductCategoriesSection /> */}
            <CarouselSection elements={carouselElementsData?.carouselElements?.data} />
            <OurOffer />
            <ContactSection />
            <RepresentationSection repLogos={repLogos?.representationLogos?.data} />
        </Layout>
    );
};

export default HomePage;
