import React, { useMemo, useState } from 'react';
import s from './NewProductsPage.module.scss';
import SubPage from 'components/SubPage/SubPage';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { addStrapiUrl } from 'utils/strapiUtils';
import BeanButton from 'components/BeanButton/BeanButton';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useQuery } from '@apollo/client';
import Loading from 'components/Loading/Loading';
import { IManufacturer } from 'types/types';
import ProductCard from 'pages/ProductsPage/ProductSelect/ProductCard/ProductCard';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import Layout from 'components/Layout/Layout';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GetNewProductsQuery } from 'gql/graphql';

export interface IProduct {
    id: number;
    attributes: {
        name: string;
        short_description: string;
        description: string;
        is_new: boolean;
        url: string;
        images: {
            id: string;
            data: {
                attributes: {
                    url: string;
                };
            }[];
        };
        subcategory: {
            data: {
                id: number;
                attributes: {
                    url: string;
                    category: {
                        data: {
                            id: number;
                            attributes: {
                                url: string;
                            };
                        };
                    };
                };
            };
        };
        manufacturer: IManufacturer;
    };
}

interface Props {}

const GET_PRODUCTS_BY_SUBCATEGORY_ID = gql`
    query GetNewProducts($manufacturerId: ID) {
        products(filters: { and: [{ is_new: { eq: true } }, { manufacturer: { id: { eq: $manufacturerId } } }] }) {
            data {
                id
                attributes {
                    name
                    url
                    is_new
                    images {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    subcategory {
                        data {
                            id
                            attributes {
                                url
                                category {
                                    data {
                                        id
                                        attributes {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                    manufacturer {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

const GET_MANUFACTURERS = gql`
    query GetManufacturers {
        manufacturers(filters: { products: { is_new: { eq: true } } }) {
            data {
                id
                attributes {
                    name
                }
            }
        }
    }
`;

const NewProductsPage = (props: Props) => {
    const { t } = useTranslation('NewProductsPage');
    const navigate = useNavigate();

    const [selectedManufacturerId, setSelectedManufacturerId] = useState<string>('unset');

    const { data: productsData, loading } = useQuery<GetNewProductsQuery>(GET_PRODUCTS_BY_SUBCATEGORY_ID, {
        variables: { manufacturerId: selectedManufacturerId === 'unset' ? undefined : selectedManufacturerId },
    });

    const { loading: manufLoading, data: manufData } = useQuery(GET_MANUFACTURERS);

    const manufacturers: IManufacturer[] = useMemo(() => manufData?.manufacturers?.data, [manufData]);

    const handleReturnClick = (): void => {
        navigate(-1);
    };

    if (loading || manufLoading)
        return (
            <>
                <Helmet>
                    <title>{t('page title')}</title>
                </Helmet>
                <Layout>
                    <SubPage currentPageName={t('page title') ?? ''}>
                        <Loading />
                    </SubPage>
                </Layout>
            </>
        );
    if (!(loading || manufLoading) && productsData?.products?.data.length === 0)
        return (
            <>
                <Helmet>
                    <title>{t('page title')}</title>
                </Helmet>
                <Layout>
                    <SubPage currentPageName={t('page title') ?? ''}>
                        <h1>{t('no products found')}</h1>
                    </SubPage>
                </Layout>
            </>
        );
    return (
        <>
            <Helmet>
                <title>{t('page title')}</title>
            </Helmet>
            <Layout>
                <SubPage currentPageName={t('page title') ?? ''}>
                    <div className={s.container}>
                        <div className={s.pageDescription}>
                            <h1 className={s.title}>{t('page title')}</h1>
                        </div>

                        <div className={s.filtersContainer}>
                            <p className={s.displayedProductsNumber}>
                                {t('displayed products', {
                                    count: productsData?.products?.data.length,
                                    total: productsData?.products?.data.length,
                                })}
                            </p>

                            <FormControl disabled={manufacturers?.length === 0} sx={{ minWidth: 200 }}>
                                {manufacturers?.length !== 0 && (
                                    <>
                                        <InputLabel id='manufacturer-filter-select-label'>
                                            {t('manufacturer')}
                                        </InputLabel>
                                        <Select
                                            labelId='manufacturer-filter-select-label'
                                            id='manufacturer-filter-select'
                                            label={t('manufacturer')}
                                            value={selectedManufacturerId}
                                            onChange={(event) => setSelectedManufacturerId(event.target.value)}
                                        >
                                            <MenuItem value={'unset'}>{t('all manufacturers')}</MenuItem>
                                            {manufacturers.map((manuf) => (
                                                <MenuItem value={manuf.id} key={manuf.id + manuf.attributes.name}>
                                                    {manuf.attributes.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </>
                                )}
                            </FormControl>
                        </div>

                        <ul className={s.productsList}>
                            {productsData?.products?.data?.map((product) => (
                                <ProductCard
                                    title={product.attributes?.name ?? ''}
                                    image={addStrapiUrl(product.attributes?.images?.data[0]?.attributes?.url ?? '')}
                                    url={`${
                                        product.attributes?.is_new
                                            ? NavigationNames.Products
                                            : NavigationNames.UsedProducts
                                    }/${product.attributes?.subcategory?.data?.attributes?.category?.data?.id}/${
                                        product.attributes?.subcategory?.data?.id
                                    }/${product.id ?? ''}`}
                                    isOfferNew={product.attributes?.is_new ?? true}
                                    key={product.id}
                                />
                            ))}
                        </ul>

                        <div className={s.returnButtonContainer}>
                            <BeanButton
                                text={t('back')}
                                onClick={handleReturnClick}
                                color='blue'
                                variant='outlined'
                                iconStart={<FontAwesomeIcon icon={faAngleLeft} />}
                            />
                        </div>
                    </div>
                </SubPage>
            </Layout>
        </>
    );
};

export default NewProductsPage;
